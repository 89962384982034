import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Alert, Button, Container, Form } from 'react-bootstrap';

import "styles/theme.css";
import getBackend from 'backend/backend';
import { Paths } from 'paths';
import { showConfirmation } from "components/misc";
import SidebarPage from 'components/sidebar_page';


export const ai_models = ["gpt-4o-mini", "gpt-4o", "o1-mini", "o1-preview"];

export const NewReportPanel = () => {
    const [tickerValue, setTickerValue] = useState("");
    const [selectedModel, setSelectedModel] = useState(ai_models[0]);
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState("");
    const paid = false;

    useEffect(() => {
        let canceled = false;

        const getSettings = async () => {
            const result = await getBackend().getSettings();
            if (canceled)
                return;

            if (result.error == null) {
                setSelectedModel(result.response.settings.ai_model);
            }
        };

        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
                return;

            if (result.error == null) {
                setProfile(result.response);
            }
        };

        getProfile();
        getSettings();

        return async () => {
            canceled = true;
        }
    }, []);

    const changeTickerValue = (e) => {
        setTickerValue(e.target.value);
    };


    const invalidTickerMessage = (message) => {
        return "Invalid ticker value: " + tickerValue + ". Error: " + message + ". Please enter a valid ticker value. Example: AAPL.";
    }

    const validateTickerValue = () => {
        if (tickerValue === "") {
            setError(invalidTickerMessage("empty value"));
            return false;
        }

        const parts = tickerValue.split(" ");
        if (parts.length !== 1) {
            setError(invalidTickerMessage("invalid format"));
            return false;
        }

        return true;
    }

    const startReport = async () => {
        if (!validateTickerValue()) {
            return;
        }

        const params = {
            ticker: tickerValue.trim().toUpperCase(),
            ai_model: selectedModel,
            paid: paid,
            votes: 1,
        };
        const result = await getBackend().createReport(params);
        if (result.error == null) {
            window.location.href = Paths.Report.path + "/" + result.response.id;
        } else {
            setError(result.error);
            if (profile === null && result.status === 401 && result.error.includes("Anonymous client limit reached")) {
                let result = await showConfirmation("You have reached your free usage limit. To continue using our services, please consider upgrading your account.", "Would you like to view our paid options?");
                if (result.isConfirmed) {
                    window.location.href = Paths.Pricing.path;
                }
            }
        }
    }

    return (
        <section className="section section-md bg-soft pt-lg-5 vh-100 d-flex" id="features">
            <Container>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Stock Ticker</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter stock ticker to analyze for investment opportunities. Example: AAPL"
                            value={tickerValue}
                            onChange={changeTickerValue}
                        />
                    </Form.Group>
                    {(profile || paid) &&
                        <Form.Group className="mb-3">
                        <Form.Label>AI Model</Form.Label>
                        <Form.Select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
                                    {ai_models.map((model, index) => (
                                        <option key={index} value={model}>{model}</option>
                                    ))}
                        </Form.Select>
                        </Form.Group>
                    }
                    <Button className="custom-button" variant="light" onClick={startReport}>
                        Start Analysis
                    </Button>
                </Form>
            </Container>
        </section>
    )
}

const NewReport = () => {
    const [t] = useTranslation('common');

    return (
        <SidebarPage title={t('New Analysis')} description={t('New Analysis')}>
            <Container className="my-5">
                <h2 className="mb-4">{t('New Analysis')}</h2>
                <NewReportPanel/>
            </Container>
        </SidebarPage>
    );
}

export default NewReport;
