import { React, useState, useEffect } from 'react';
import getBackend from 'backend/backend';
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Alert } from 'react-bootstrap';
import SidebarPage from 'components/sidebar_page';
import { ai_models } from './new_report';

const Settings = () => {
    const [t] = useTranslation('common');
    const [settings, setSettings] = useState({ ai_model: ''});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let canceled = false;

        const fetchSettings = async () => {
            try {
                const result = await getBackend().getSettings();
                if (!canceled && result.error == null) {
                    setSettings(result.response.settings);
                } else if (!canceled) {
                    setError(result.error);
                }
            } catch (err) {
                if (!canceled) setError(err.message);
            } finally {
                if (!canceled) setLoading(false);
            }
        };

        fetchSettings();

        return () => {
            canceled = true;
        };
    }, []);

    const handleAiModel = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            ai_model: e.target.value
        }));
    };

    const handleSave = async () => {
        try {
            const result = await getBackend().setSettings({"settings": settings});
            if (result.error != null) {
                setError(result.error);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <SidebarPage title={t('Settings')} description={t('Settings')}>
            <Container className="my-5">

                <h2 className="mb-4">{t('Settings')}</h2>
                {loading &&
                    <div>{t('Loading...')}</div>
                }
                {error &&
                    <Alert variant="danger">{t('Error')}: {error}</Alert>
                }
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Default AI model</Form.Label>
                        <Form.Select value={settings.ai_model} onChange={handleAiModel}>
                                    {ai_models.map((model, index) => (
                                        <option key={index} value={model}>{model}</option>
                                    ))}
                        </Form.Select>
                    </Form.Group>
                    <Button variant="light" className="custom-button" onClick={handleSave}>{t('Save')}</Button>
                </Form>
            </Container>
        </SidebarPage>
    );
};

export default Settings;
