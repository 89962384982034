import {React} from 'react';
import { Container, Row, Col, Alert, Form, Button} from 'react-bootstrap';
import getBackend from 'backend/backend';
import { Paths } from 'paths';
import { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { DividerOr } from 'components/misc';
import Page from 'components/page';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Signin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation('common');

    const doSignin = async (authType, name, password) => {
        setError("");
        setLoading(true);

        const result = await getBackend().signin({auth_type: authType, name: name, password: password});
        if (result.error != null) {
            setError(result.error);
        } else {
            window.location.href = Paths.Profile.path;
        }

        setLoading(false);
    };

    const handleSigninSubmit = async (event) => {
        event.preventDefault();

        doSignin("email", email, password);
    };

    return (
        <Page title={t('Sign In')} description={t('Sign In')}>
            <Container>
            <Row className="justify-content-md-center vh-100">
                <Col md={6}>
                <h2 className="my-4 text-center">{t('Sign In')}</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        doSignin("google", "", credentialResponse.credential);
                    }}
                    onError={() => {
                        setError(t('Google signin: something went wrong'));
                    }}
                />
                <DividerOr/>
                <Form onSubmit={handleSigninSubmit}>
                    <Form.Group controlId="name">
                    <Form.Label>{t('Email')}</Form.Label>
                    <Form.Control
                        className="custom-input"
                        type="text"
                        placeholder={t('Enter your email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </Form.Group>

                    <Form.Group controlId="password">
                    <Form.Label>{t('Password')}</Form.Label>
                    <Form.Control
                        className="custom-input"
                        type="password"
                        placeholder={t('Enter your password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    </Form.Group>

                    <Button className="custom-button mt-3" variant="light" type="submit" disabled={loading}>
                        {loading ? t('Signing In...') : t('Sign In')}
                    </Button>
                    <div className="mt-3">
                        {t("Don't have an account?")} <Link to={Paths.Signup.path} className="signup-link">{t('Sign Up')}</Link>
                    </div>
                </Form>
                </Col>
            </Row>
            </Container>
        </Page>
    );
};

export default Signin;