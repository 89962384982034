import { React, useState, useEffect } from 'react';
import getBackend from 'backend/backend';
import { useTranslation } from "react-i18next";
import { unixTimestampToString } from 'components/misc';
import { Table, Button, Container } from 'react-bootstrap';
import SidebarPage from 'components/sidebar_page';
import { Paths } from "paths";

const Alerts = () => {
    const [alerts, setAlerts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [t] = useTranslation('common');

    const itemsPerPage = 10;

    useEffect(() => {
        let canceled = false;

        const fetchAlerts = async () => {
            const offset = (currentPage - 1) * itemsPerPage;
            const result = await getBackend().getAlerts(offset, itemsPerPage);
            if (canceled) return;

            if (result.error == null) {
                setAlerts(result.response.alerts);
            }
        };

        fetchAlerts();

        return () => {
            canceled = true;
        };
    }, [currentPage]);

    const paginatedAlerts = alerts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <SidebarPage title={t('Alerts')} description={t('Alerts')}>
            <Container className="my-5">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2>{t('Alerts')}</h2>
                    <Button
                        variant="light"
                        className="custom-button"
                        onClick={() => window.location.href = Paths.NewAlert.path}
                    >
                        {t('Add Alert')}
                    </Button>
                </div>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('ID')}</th>
                            <th>{t('Ticker')}</th>
                            <th>{t('Condition')}</th>
                            <th>{t('Created')}</th>
                            <th>{t('Status')}</th>
                            <th>{t('Checks')}</th>
                            <th>{t('Fires')}</th>
                            <th>{t('Errors')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedAlerts.map(alert => (
                            <tr key={alert.id}>
                                <td>{alert.id}</td>
                                <td>{alert.ticker}</td>
                                <td>{alert.condition}</td>
                                <td>{unixTimestampToString(alert.created_ts/1000000)}</td>
                                <td>{alert.status}</td>
                                <td>{alert.check_count}</td>
                                <td>{alert.fire_count}</td>
                                <td>{alert.error_count}</td>
                                <td>
                                    <Button 
                                        variant="danger" 
                                        size="sm"
                                        onClick={async () => {
                                            const result = await getBackend().deleteAlert(alert.id);
                                            if (result.error == null) {
                                                setAlerts(alerts.filter(a => a.id !== alert.id));
                                            }
                                        }}
                                    >
                                        {t('Delete')}
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {alerts.length > itemsPerPage &&
                    <div className="mt-3">
                        <Button 
                            variant="light" 
                            className="custom-button"
                            disabled={currentPage === 1} 
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            {t('Previous')}
                        </Button>
                        <Button 
                            variant="light" 
                            className="custom-button"
                            disabled={currentPage * itemsPerPage >= alerts.length} 
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                        {t('Next')}
                        </Button>
                    </div>
                }
            </Container>
        </SidebarPage>
    );
};

export default Alerts;
