import HttpClient from './http';
import devmode from './env';
import { delay } from 'components/misc';
import { getClientHash } from 'components/misc';

class Backend {
    constructor() {
        this.host_url = (devmode()) ? "http://127.0.0.1:8080" : window.location.origin;
        this.client = new HttpClient(this.host_url);

    }

    async renewToken() {
        for (let i = 0; i < 5; i++) {
            const token = window.localStorage.getItem("token");
            const refresh_token = window.localStorage.getItem("refresh_token");
            if (token != null) {
                const result = await this.client.post('/api/account/token', {refresh_token: refresh_token}, {"X-Token": token});
                if (result.error == null) {
                    window.localStorage.setItem("token", result.response.token);
                    window.localStorage.setItem("refresh_token", result.response.refresh_token);
                    return;
                }
                await delay(100);
            }
        }
    }

    updateTokenFromResult(result) {
        if (result.error == null) {
            window.localStorage.setItem("token", result.response.token);
            window.localStorage.setItem("refresh_token", result.response.refresh_token);
        } else {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("refresh_token");
        }
    }

    async health() {
        return await this.client.get('/api/health', {});
    }

    async signin(data) {
        const result = await this.client.post('/api/account/signin', data, {});
        this.updateTokenFromResult(result);
        return result;
    }

    async signup(data) {
        return await this.client.post('/api/account/signup', data, {});
    }

    async signout() {
        const token = window.localStorage.getItem("token");
        if (token != null) {
            const result = await this.client.post('/api/account/signout', {}, {"X-Token": token});
            if (result.error == null) {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("refresh_token");
            }
            return result;
        }
        return {response: {auth_type: "", account_id: ""}, error: null};
    }

    async profile() {
        const token = window.localStorage.getItem("token");
        if (token != null) {
            return await this.client.get('/api/account/profile', {"X-Token": token});
        } else {
            return {response: null, error: "no token"};
        }
    }

    async verifyEmail(data) {
        return await this.client.post('/api/account/verify-email', data, {});
    }

    async deleteAccount(data) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/delete', {}, {"X-Token": token});
    }

    async getBillingInfo() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/billing/billing-info', {"X-Token": token});
    }

    async setBillingSession(session_id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/billing/billing-session', { session_id: session_id}, {"X-Token": token});
    }

    async updateBillingSubscription(price_lookup_key) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/billing/update-subscription', {price_lookup_key: price_lookup_key}, {"X-Token": token});
    }

    async getNotifications(offset, limit) {
        const token = window.localStorage.getItem("token");
        return await this.client.get(`/api/notifications?offset=${offset}&limit=${limit}`, {"X-Token": token});
    }

    async getUnreadNotifications(offset, limit) {
        const token = window.localStorage.getItem("token");
        return await this.client.get(`/api/notifications/unread?offset=${offset}&limit=${limit}`, {"X-Token": token});
    }

    async markNotificationRead(id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/notifications/mark-read', {id: id}, {"X-Token": token});
    }

    async deleteNotification(id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/notifications/delete', {id: id}, {"X-Token": token});
    }

    async getSettings() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/settings', {"X-Token": token});
    }

    async setSettings(settings) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/settings', settings, {"X-Token": token});
    }

    async getReport(id) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/stock-analysis/' + id, {"X-Token": token});
    }

    async createReport(params) {
        const client_hash = await getClientHash();

        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/stock-analysis',
            {ticker: params.ticker, ai_model: params.ai_model,
                paid: params.paid, client_hash: client_hash}, {"X-Token": token});
    }

    async getReports(offset, limit) {
        const token = window.localStorage.getItem("token");
        return await this.client.get(`/api/stock-analyses?offset=${offset}&limit=${limit}`, {"X-Token": token});
    }

    async newAlert(params) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/stock-alert',
            {ticker: params.ticker, ai_model: params.ai_model, condition: params.condition}, {"X-Token": token});
    }

    async getAlerts(offset, limit) {
        const token = window.localStorage.getItem("token");
        return await this.client.get(`/api/stock-alerts?offset=${offset}&limit=${limit}`, {"X-Token": token});
    }

    async deleteAlert(id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/stock-alert/delete', {id: id}, {"X-Token": token});
    }
}

var backend = null;

function getBackend() {
    if (backend != null)
        return backend;

    backend = new Backend();
    return backend;
}

export default getBackend;
