import {React, useState, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

import getBackend from 'backend/backend';
import { Paths } from 'paths';
import { useTranslation } from "react-i18next";

import ReactGA from "react-ga4";
import { Col, Row, Card, Button, Container, ListGroup, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { showError, showMessage, showConfirmation } from "components/misc";
import SidebarPage from 'components/sidebar_page';

const planToName = {
  "stockadvisorai_free_plan" : "Free",
  "stockadvisorai_starter_plan" : "Starter",
  "stockadvisorai_pro_plan": "Pro"
};

const planToPrice = {
  "stockadvisorai_free_plan" : "$0 per month",
  "stockadvisorai_starter_plan" : "$8 per month",
  "stockadvisorai_pro_plan": "$29 per month"
};

const planToPurpose = {
  "stockadvisorai_free_plan": "Perfect for individuals exploring basic features",
  "stockadvisorai_starter_plan": "Ideal for personal projects",
  "stockadvisorai_pro_plan": "Optimized for professionals needing advanced tools"
};

const planProperties = {
  "stockadvisorai_free_plan" : ["No credit card required", "Stock analysis"],
  "stockadvisorai_starter_plan": ["Trading alerts", "AI model selection", "Up to 3 active alerts"],
  "stockadvisorai_pro_plan" : ["Best AI models for better results", "Up to 10 active alerts", "Priority support"]
};

/* TODO:
const canUpgradePlan = (plan) => {
  switch (plan) {
  case "stockadvisorai_free_plan":
    return "stockadvisorai_starter_plan";
  default:
    return "";
  }
}

const canDowngradePlan = (plan) => {
  switch (plan) {
  case "stockadvisorai_starter_plan":
    return "stockadvisorai_free_plan";
  default:
    return "";
  }
}
*/

const onChangeSubscription = async (updateCallback, newSubPriceLookupKey) => {
  ReactGA.event({
    category: "subscription",
    action: "change",
  });

  let result = await showConfirmation("Change subscription", "Are you going to change current subscription?");
  if (result.isConfirmed) {
    const result = await getBackend().updateBillingSubscription(newSubPriceLookupKey);
    if (result.error == null) {
      await showMessage("Change subscription", "Please wait ~1 min to update subscription status and refresh the page");
      if (updateCallback)
        updateCallback();
    } else
      await showError(result.error);
  }
}

const BasePlanEntry = (props) => {
  return (
      <>
      <Card>
        <Card.Header>
          <h4 className="mb-3 text-black">{planToName[props.plan]}</h4>
          <div className="d-flex mb-3"><strong>{planToPrice[props.plan]}</strong></div>
          <p className="fw-normal font-small mb-0">
            {planToPurpose[props.plan]}
          </p>
        </Card.Header>
        <Card.Body>
          {props.children}

          <ListGroup className="simple-list">
            <ListGroup.Item className="fw-normal border-0">
              {planProperties[props.plan].map((object, i) => {return <div key={i}>{object}<br/></div>})}
            </ListGroup.Item>
          </ListGroup>

        </Card.Body>
      </Card>
      </>
  )
}

export const FreePlanEntry = (props) => {

  return (
    <>
      <BasePlanEntry plan="stockadvisorai_free_plan">
        {props.link &&
        <Button as={Link} variant="light" to={props.link} className="w-100 rounded animate-up-2 custom-button">
          Try now<span className="icon icon-xs ms-3" />
        </Button>
        }

        {props.subscription &&
          <>
          {props.subscription.price_lookup_key === "stockadvisorai_free_plan" ?
            <>
            <Button variant="light" className="w-100 rounded animate-up-2 custom-button" onClick={(e) => {e.preventDefault();}}>
              Current subscription<span className="icon icon-xs ms-3" />
            </Button>
            </>
            :
            <Button variant="light" className="w-100 rounded animate-up-2 custom-button" onClick={(e) => {e.preventDefault(); onChangeSubscription(props.updateCallback, "");}}>
              Select<span className="icon icon-xs ms-3" />
            </Button>
          }
          </>
        }

      </BasePlanEntry>
    </>
  )
}

export const PaidPlanEntry = (props) => {
  return (
    <>
      <BasePlanEntry plan={props.plan}>
        {props.link &&
        <Button as={Link} variant="light" to={props.link} className="w-100 rounded animate-up-2 custom-button">
          Try now<span className="icon icon-xs ms-3" />
        </Button>
        }

        {props.subscription &&
          <>
          {props.subscription.price_lookup_key === props.plan ?
            <>
            <Form action="/api/billing/checkout-create-portal-session" method="POST">
              <input type="hidden" name="token" value={props.token}/>
              <Button variant="light" className="w-100 rounded animate-up-2 custom-button" type="submit">
                Manage subscription
              </Button>
            </Form>
            <Container style={{ height: '5px' }}></Container>
              <Button variant="light" className="w-100 rounded animate-up-2 custom-button" onClick={(e) => {e.preventDefault(); onChangeSubscription(props.updateCallback, "");}}>
                Cancel subscription<span className="icon icon-xs ms-3" />
              </Button>
            </>
            :
            <Form action="/api/billing/checkout-create-session" method="POST">
              <input type="hidden" name="price_lookup_key" value={props.plan} />
              <input type="hidden" name="token" value={props.token}/>
              <Button variant="light" className="w-100 rounded animate-up-2 custom-button" type="submit">
                Buy<span className="icon icon-xs ms-3" />
              </Button>
            </Form>
          }
          </>
        }
      </BasePlanEntry>
    </>
  )
}

export const PricingPresentation = (props) => {
  return (
    <Container id="pricing">
      <Row className="justify-content-center mb-6">
        <Col xs={12} lg={9} className="text-center">
          <h1 className="display-3 fw-light mb-4">Pricing</h1>
        </Col>
      </Row>
      <Row className="mb-4 mb-lg-5">
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <FreePlanEntry link={Paths.Signup.path}/>
        </Col>
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <PaidPlanEntry plan="stockadvisorai_starter_plan" link={Paths.Signup.path}/>
        </Col>
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <PaidPlanEntry plan="stockadvisorai_pro_plan" link={Paths.Signup.path}/>
        </Col>
      </Row>
    </Container>
  )
}

const ManageBillingInfo = (props) => {

/* TODO:
  const getSubscriptionStatus = (state, substate) => {
    if (substate === "cancel_at_period_end") {
      return state + " and will be effectively canceled at period end";
    }

    return state;
  }
*/

  return (
    <>
      <Row className="justify-content-center mb-6">
        <Col xs={12} lg={9} className="text-center">
          <h2 className="display-3 fw-light mb-4">Billing</h2>
        </Col>
      </Row>
      <Row className="mb-4 mb-lg-5">
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <FreePlanEntry token={props.token} updateCallback={props.updateCallback} subscription={props.subscription}/>
        </Col>
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <PaidPlanEntry plan="stockadvisorai_starter_plan" token={props.token} updateCallback={props.updateCallback} subscription={props.subscription}/>
        </Col>
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <PaidPlanEntry plan="stockadvisorai_pro_plan" token={props.token} updateCallback={props.updateCallback} subscription={props.subscription}/>
        </Col>
      </Row>
    </>
  );
};


const Billing = () => {
    const [t] = useTranslation('common');
    let [token, setToken] = useState('');
    let [billingInfo, setBillingInfo] = useState({});
    let [updateSeqno, setUpdateSeqno] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
  
    const updateCallback = () => {
      setUpdateSeqno(updateSeqno + 1);
    }
  
    useEffect(() => {
      const query = new URLSearchParams(location.search);
      let canceled = false;
  
      setToken(window.localStorage.getItem("token"));
  
      ReactGA.event({
        category: "checkout",
        action: "show",
      });
  
      if (query.get("success")) {
        ReactGA.event({
          category: "checkout",
          action: "success",
        });
  
        let sessionId = query.get("session_id");
  
        const setBillingSession = async (localSessionId) => {
          const result = await getBackend().setBillingSession(localSessionId);
          if (result.error == null) {
            await showMessage("Subscription creation", "Please wait ~1 min to update subscription status and refresh the page");
          } else {
            await showError(result.error)
          }
  
          query.delete("success");
          query.delete("session_id");
          navigate({
            pathname: location.pathname,
            search: `?${query.toString()}`,
          });
        }
  
        if (!canceled)
          setBillingSession(sessionId);
      }
  
      if (query.get('canceled')) {
        ReactGA.event({
          category: "checkout",
          action: "canceled",
        });
        query.delete("canceled");
        query.delete("session_id");
        navigate({
          pathname: location.pathname,
          search: `?${query.toString()}`,
        });
      }
  
      const getBillingInfo = async () => {
        const result = await getBackend().getBillingInfo();
        if (canceled)
          return;
  
        if (result.error == null) {
          setBillingInfo(result.response);
        } else {
          if (!canceled)
            await showError(result.error);
        }
      };
  
      getBillingInfo();
      return () => {
        canceled = true;
      }
  
    }, [location.search, location.pathname, navigate, updateSeqno]);

    return (
      <SidebarPage title={t('Billing')} description={t('Billing')}>
        <Container className="my-5">
          {(billingInfo.subscription_id !== "" && billingInfo.subscription_state !== "canceled")
            ? <ManageBillingInfo updateCallback={updateCallback} token={token} subscription={{state: billingInfo.subscription_state, substate: billingInfo.subscription_substate, price_lookup_key: billingInfo.subscription_price_lookup_key}} />
            : <ManageBillingInfo updateCallback={updateCallback} token={token} subscription={{price_lookup_key: "stockadvisorai_free_plan"}} />
            }
          </Container>

      </SidebarPage>
  );
};

export default Billing;
