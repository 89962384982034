import { React, useState, useEffect } from 'react';
import { Nav, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Paths } from 'paths';
import "styles/theme.css";
import getBackend from 'backend/backend';
import { useLocation } from 'react-router-dom';

export const Sidebar = (props) => {
    const [profile, setProfile] = useState(null);
    const location = useLocation();
    useEffect(() => {
        let canceled = false;

        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
                return;

            if (result.error == null) {
                setProfile(result.response);
            } else {
                window.location.href = Paths.NotFound.path;
            }
        };

        getProfile();

        return async () => {
            canceled = true;
        }
    }, []);

    return (
            <Row>
                <Col xs={12} sm={6} md={4} lg={3} xl={2} xxl={2} className="border-end custom-sidebar">
                    <Nav className="flex-column">
                        <Nav.Link as={NavLink} to={Paths.NewAlert.path} className={`header-text sidebar-link ${location.pathname === Paths.NewAlert.path ? 'text-decoration-underline' : ''}`}>New Alert</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Alerts.path} className={`header-text sidebar-link ${location.pathname === Paths.Alerts.path ? 'text-decoration-underline' : ''}`}>Alerts</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.NewReport.path} className={`header-text sidebar-link ${location.pathname === Paths.NewReport.path ? 'text-decoration-underline' : ''}`}>New Analysis</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Reports.path} className={`header-text sidebar-link ${location.pathname === Paths.Reports.path ? 'text-decoration-underline' : ''}`}>Analyses</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Notifications.path} className={`header-text sidebar-link ${location.pathname === Paths.Notifications.path ? 'text-decoration-underline' : ''}`}>Notifications {profile !== null && profile.unread_notifications_count > 0 ? `(${profile.unread_notifications_count})` : ''}</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Settings.path} className={`header-text sidebar-link ${location.pathname === Paths.Settings.path ? 'text-decoration-underline' : ''}`}>Settings</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Profile.path} className={`header-text sidebar-link ${location.pathname === Paths.Profile.path ? 'text-decoration-underline' : ''}`}>Profile</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Billing.path} className={`header-text sidebar-link ${location.pathname === Paths.Billing.path ? 'text-decoration-underline' : ''}`}>Billing</Nav.Link>
                    </Nav>
                </Col>
                <Col xs={12} sm={6} md={8} lg={9} xl={10} xxl={10} className="min-vh-100">
                    {props.children}
                </Col>
            </Row>
    );
};

export default Sidebar;
