import { React } from 'react';
import "styles/theme.css";
import Footer from './footer';
import Header from './header';
import { Consts } from 'consts';
import { Helmet } from 'react-helmet';
import Sidebar from './sidebar';

export const SidebarPage = (props) => {
    return (
        <>
            <Helmet>
                <title>{props.title} | {Consts.ProjectName}</title>
                <meta name="description" content={props.description} />
            </Helmet>
            <Header/>
                <Sidebar>
                    <main className="main-content">
                        {props.children}
                    </main>
                </Sidebar>
            <Footer/>
        </>
    );
};

export default SidebarPage;
