import React, { useState, useEffect } from "react";

import { Container, Card, ProgressBar, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';


import { Paths } from "paths";
import { useTranslation } from "react-i18next";

import getBackend from "backend/backend";
import ShareButton from "components/share_button";
import Page from 'components/page';
import SidebarPage from 'components/sidebar_page';

const StockAnalysis = (props) => {
    const result = props.result;
    const [showInsights, setShowInsights] = useState(true);
    const [showNews, setShowNews] = useState(false);
    const [showTechnicalIndicators, setShowTechnicalIndicators] = useState(false);
    const [showFinancials, setShowFinancials] = useState(false);

    return (
        <div className="analysis-container">
            <div className="analysis-header mb-4">
                <div className="d-flex align-items-center mb-3">
                    <h4 className="mb-0 me-3">Decision: {result.decision}</h4>
                    <div className="text-muted">
                        Price: {result.price} | Confidence: {result.confidence}
                    </div>
                </div>
                
                <div className="mb-3">
                    <strong>Conclusion:</strong> {result.analysis.conclusion}
                </div>
            </div>

            <div className="analysis-sections">
                <div className="analysis-section mb-3">
                    <Button 
                        variant="outline-secondary" 
                        size="sm" 
                        className="w-100 text-start"
                        onClick={() => setShowInsights(!showInsights)}
                    >
                        {showInsights ? '▼' : '▶'} Key Insights
                    </Button>
                    {showInsights && (
                        <div className="p-3 border-start border-end border-bottom rounded-bottom">
                            {result.analysis.points.map((point, i) => (
                                <div key={i} className="mb-2">
                                    <strong>{point.title}:</strong> {point.text}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="analysis-section mb-3">
                    <Button 
                        variant="outline-secondary" 
                        size="sm" 
                        className="w-100 text-start"
                        onClick={() => setShowNews(!showNews)}
                    >
                        {showNews ? '▼' : '▶'} Related News
                    </Button>
                    {showNews && result.news && (
                        <div className="p-3 border-start border-end border-bottom rounded-bottom">
                            {result.news.map((newsItem, i) => (
                                <div key={i} className="mb-3 border-bottom pb-2">
                                    <h6>
                                        <a href={newsItem.news_url} target="_blank" rel="noopener noreferrer">
                                            {newsItem.title}
                                        </a>
                                    </h6>
                                    <div className="small text-muted mb-1">
                                        {newsItem.source_name} • {newsItem.date} • Sentiment: {newsItem.sentiment}
                                    </div>
                                    <div className="small">{newsItem.text}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="analysis-section mb-3">
                    <Button 
                        variant="outline-secondary" 
                        size="sm" 
                        className="w-100 text-start"
                        onClick={() => setShowTechnicalIndicators(!showTechnicalIndicators)}
                    >
                        {showTechnicalIndicators ? '▼' : '▶'} Technical Indicators
                    </Button>
                    {showTechnicalIndicators && result.trading_signals && (
                        <div className="p-3 border-start border-end border-bottom rounded-bottom">
                            <div className="row">
                                <div className="col-md-6">
                                    <div><strong>Price:</strong> {Number(result.trading_signals.current_price).toFixed(2)}</div>
                                    <div><strong>Volume:</strong> {Number(result.trading_signals.current_volume).toLocaleString()}</div>
                                    <div><strong>ATR:</strong> {Number(result.trading_signals.atr).toFixed(2)}</div>
                                    <div><strong>RSI:</strong> {Number(result.trading_signals.rsi).toFixed(2)}</div>
                                    <div><strong>MACD:</strong> {Number(result.trading_signals.macd).toFixed(2)}</div>
                                    <div><strong>CCI:</strong> {Number(result.trading_signals.cci).toFixed(2)}</div>
                                    <div><strong>ROC:</strong> {Number(result.trading_signals.roc).toFixed(2)}</div>
                                </div>
                                <div className="col-md-6">
                                    <div><strong>Bollinger Upper:</strong> {Number(result.trading_signals.bollinger_upper).toFixed(2)}</div>
                                    <div><strong>Bollinger Lower:</strong> {Number(result.trading_signals.bollinger_lower).toFixed(2)}</div>
                                    <div><strong>SMA 5:</strong> {Number(result.trading_signals.sma_5_curr).toFixed(2)}</div>
                                    <div><strong>SMA 20:</strong> {Number(result.trading_signals.sma_20_curr).toFixed(2)}</div>
                                    <div><strong>Stochastic K:</strong> {Number(result.trading_signals.stochastic_k).toFixed(2)}</div>
                                    <div><strong>OBV:</strong> {Number(result.trading_signals.obv).toLocaleString()}</div>
                                    <div><strong>Momentum:</strong> {Number(result.trading_signals.momentum).toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="analysis-section mb-3">
                    <Button 
                        variant="outline-secondary" 
                        size="sm" 
                        className="w-100 text-start"
                        onClick={() => setShowFinancials(!showFinancials)}
                    >
                        {showFinancials ? '▼' : '▶'} Financial Data
                    </Button>
                    {showFinancials && result.financial_data && (
                        <div className="p-3 border-start border-end border-bottom rounded-bottom">
                            {Object.entries(result.financial_data).map(([category, data]) => (
                                <div key={category} className="mb-3">
                                    <h6 className="text-capitalize">{category.replace(/_/g, ' ')}</h6>
                                    <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                                        {Object.entries(data).map(([key, value]) => (
                                            <div key={key} className="small">
                                                <strong className="text-capitalize">{key.replace(/_/g, ' ')}:</strong>{' '}
                                                {typeof value === 'number' 
                                                    ? Number(value).toLocaleString()
                                                    : value}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};


const ReportProgressBar = (props) => {
    return (
        <ProgressBar animated variant="primary" now={props.progress} label={`${props.progress}%`} className="report-progress-bar"/>
    );
}

const ReportCard = (props) => {
    const report = props.report;

    const handleDownload = () => {
        if (!report.result) {
            return;
        }

        // Create CSV content
        const headers = ['Ticker', 'Decision', 'AI Model', 'Confidence', 'Price', 'Date', 'Conclusion'];
        const rows = [
            [
                report.ticker,
                report.result.decision,
                report.ai_model,
                report.result.confidence,
                report.result.price,
                report.result.date,
                report.result.analysis.conclusion
            ]
        ];

        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.map(cell => `"${cell?.toString().replace(/"/g, '""') || ''}"`).join(','))
        ].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `stockadvisorai-analysis-${report.ticker || 'report'}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Container className="min-vh-100 my-5">
            <section className="justify-content-center">
                <Card>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <Card.Title>
                            <h1>Stock analysis for {report.ticker}</h1>
                            <h6>AI Model: {report.ai_model}</h6>
                        </Card.Title>
                        <div>
                            <Button variant="light" className="custom-button me-2" onClick={handleDownload}>
                                Download CSV <i className="bi bi-download"></i>
                            </Button>
                            <ShareButton reportLink={window.location.href}/>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {report.progress < 100 && report.report_error === "" &&
                            <>
                            {report.status !== "" &&
                                <h5>Status: {report.status}. Please wait and do not close this page...</h5>
                            }
                            <ReportProgressBar progress={report.progress}/>
                            </>
                        }
                        {report.report_error !== "" &&
                            <Alert variant="danger" className="text-center">
                                Error: {report.report_error}
                            </Alert>
                        }
                        {report.result && report.report_error === "" && report.progress === 100 && !report.hidden &&
                            <StockAnalysis result={report.result}/>
                        }
                        {(report.hidden && report.payment_link !== "") &&
                            <Alert variant="info" className="text-center">
                                <h5>The analysis is hidden. Complete the payment to view it.</h5>
                                <Button variant="light" className="custom-button mt-3" as={Link} to={report.payment_link}>
                                    Unlock Stock Analysis <span className="icon icon-xs ms-2" />
                                </Button>
                            </Alert>
                        }
                    </Card.Body>
                </Card>
            </section>
        </Container>
    )
}

const Report = () => {
    const {report_id} = useParams();
    const [t] = useTranslation('common');
    const [profile, setProfile] = useState(null);
    const [report, setReport] = useState({progress: 1, report_error: "", status: "loading",
        issues: [], hidden: false, payment_link: "", ticker: "", ai_model: ""});

    useEffect(() => {
      let canceled = false;
  
        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
                return;

            if (result.error == null) {
                setProfile(result.response);
            }
        };

        const getReport = async () => {
            const result = await getBackend().getReport(report_id);
            if (canceled)
                return {progress: 0, error: ""};

            if (result.error == null) {
                setReport(result.response);

                return {progress: result.response.progress, error: result.response.report_error};
            } else {
                window.location.href = Paths.NotFound.path;
            }
        };

        getProfile();

        const timer = setInterval(async () => {
            if (!canceled) {
                const {progress, error} = await getReport();
                if (progress >= 100 || error !== "") {
                    clearInterval(timer);
                }
            }
        }, 1000); // Update every 1 second

        return async () => {
            clearInterval(timer);
            canceled = true;
        }
  }, [report_id]);

  return (
    <>
        {profile ?
            <SidebarPage title={t('Stock Analysis')} description={t('Stock Analysis')}>
                <ReportCard
                    report={report}
                />
            </SidebarPage>
            :
            <Page title={t('Stock Analysis')} description={t('Stock Analysis')}>
                <ReportCard
                    report={report}
                />
            </Page>
        }
    </>
  );
};

export default Report;