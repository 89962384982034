import { React, useState, useEffect } from 'react';
import getBackend from 'backend/backend';
import { useTranslation } from "react-i18next";
import { unixTimestampToString } from 'components/misc';
import { Table, Button, Form, Container} from 'react-bootstrap'; // Importing necessary components
import SidebarPage from 'components/sidebar_page';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [unreadOnly, setUnreadOnly] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [t] = useTranslation('common');

    const itemsPerPage = 10;

    useEffect(() => {
        let canceled = false;

        const fetchNotifications = async () => {
            const offset = (currentPage - 1) * itemsPerPage;
            const result = unreadOnly
                ? await getBackend().getUnreadNotifications(offset, itemsPerPage)
                : await getBackend().getNotifications(offset, itemsPerPage);
            if (canceled) return;

            if (result.error == null) {
                setNotifications(result.response.notifications);
            }
        };

        fetchNotifications();

        return () => {
            canceled = true;
        };
    }, [currentPage, unreadOnly]);

    const handleUnreadToggle = () => {
        setUnreadOnly(!unreadOnly);
    };

    const handleSelectNotification = (id) => {
        setSelectedNotifications(prev =>
            prev.includes(id) ? prev.filter(nid => nid !== id) : [...prev, id]
        );
    };

    const markAsRead = async () => {
        for (const id of selectedNotifications) {
            await getBackend().markNotificationRead(id);
        }
        setNotifications(notifications.map(n =>
            selectedNotifications.includes(n.id) ? { ...n, read: true } : n
        ));
        setSelectedNotifications([]);
    };

    const paginatedNotifications = notifications.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <SidebarPage title={t('Notifications')} description={t('Notifications')}>
            <Container className="my-5">

                <h2 className="mb-4">{t('Notifications')}</h2>

                <Form.Check
                    type="checkbox" 
                    label={t('Show unread notifications only')} 
                    checked={unreadOnly} 
                    onChange={handleUnreadToggle} 
                />

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('Select')}</th>
                            <th>{t('Timestamp')}</th>
                            <th>{t('Severity')}</th>
                            <th>{t('Message')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedNotifications.map(notification => (
                            <tr key={notification.id}>
                                <td>
                                    <Form.Check 
                                        type="checkbox" 
                                        checked={selectedNotifications.includes(notification.id)}
                                        onChange={() => handleSelectNotification(notification.id)}
                                    />
                                </td>
                                <td>{unixTimestampToString(notification.timestamp)}</td>
                                <td>{notification.severity}</td>
                                <td>{notification.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Button variant="light" className="custom-button" onClick={markAsRead}>{t('Mark as Read')}</Button>

                {notifications.length > itemsPerPage &&
                    <div className="mt-3">
                        <Button 
                            className="custom-button"
                            variant="light" 
                            disabled={currentPage === 1} 
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            {t('Previous')}
                        </Button>
                        <Button 
                            className="custom-button"
                            variant="light" 
                            disabled={currentPage * itemsPerPage >= notifications.length} 
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                            {t('Next')}
                        </Button>
                    </div>
                }
            </Container>
        </SidebarPage>
    );
};

export default Notifications;
