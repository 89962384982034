import devmode from './env';
import { v4 as uuidv4 } from 'uuid';


class ApiError extends Error {
    constructor(request_id, status, message) {
        if (message.length > 0) {
            super(message);
        } else {
            super("undefined error");
        }
        this.name = "ApiError";
        this.request_id = request_id;
        this.status = status;
    }

    toString() {
        return `${this.name}: Request ID: ${this.request_id}, HTTP Status: ${this.status}, Message: "${this.message}"`;
    }
}


class HttpClient {
    constructor(host) {
        this.post = this.post.bind(this);
        this.get = this.get.bind(this);
        this.host = host;
        this.devmode = devmode();
    }

    async try_decode_error(resp) {
        try {
            const response = await resp.json();
            if (typeof response.error === 'string' && response.error.length > 0) {
                return response.error
            }
        } catch (e) {
            if (this.devmode) {
                console.log("can't parse response: " + e);
            }
        }

        return "";
    }

    async do(method = 'GET', url = '', data = {}, headers = {}) {
        const full_url = this.host + url;
        const request_id = uuidv4();

        try {
            headers["X-RequestId"] = request_id;
            headers["Content-Type"] = "application/json";

            if (this.devmode)
                console.log(method + " " + full_url + " " + JSON.stringify(data) + " rid: " + request_id);

            // Default options are marked with *
            const options = {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: headers,
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer' // no-referrer, *client
            };
            if (method !== 'GET' && method !== 'HEAD') {
                options.body = JSON.stringify(data);
            }
            const resp = await fetch(full_url, options);
            if (!resp.ok) {
                throw new ApiError(request_id, resp.status, await this.try_decode_error(resp));
            }

            const response = await resp.json();
            if (this.devmode)
                console.log(method + " " + full_url + " " + JSON.stringify(data) + " " + JSON.stringify(response));

            if (response.error) {

                if (typeof response.error !== 'string') {
                    throw new Error("Unexpected type of .error " + typeof response.error);
                }

                if (response.error.length > 0) {
                    throw new ApiError(request_id, resp.status, response.error);
                }
            }

            return {response: response, error: null, status: 200};
        } catch (e) {
            if (this.devmode)
                console.log(method + " " + full_url + " " + JSON.stringify(data) + " error: " + e + " rid: " + request_id);

            if (e instanceof ApiError) {
                return {response: null, error: e.toString(), status: e.status};
            }

            return {response: null, error: `Unexpected error: ${e}`, status: 500};
        }
    }

    async post(url = '', data = {}, headers = {}) {
        return await this.do("POST", url, data, headers);
    }

    async get(url = '', headers = {}) {
        return await this.do("GET", url, {}, headers);
    }

}

export default HttpClient