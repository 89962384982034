import React from "react";
import {Row, Col} from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function unixTimestampToString(ts) {
    const dateObj = new Date(1000*ts);
    return dateObj.toUTCString();
}

export function timestampToString(ts) {
    const dateObj = new Date(ts/1000);
    return dateObj.toUTCString();
}

export function timestampToStringWithMillis(ts) {
    const dateObj = new Date(ts/1000);
    return dateObj.toISOString();
}

export const capitalizeFirstLetter = (string) => (
    string[0].toUpperCase() + string.slice(1)
);

export const getFirstLetterOfEachWord = (text) => (
    text.match(/\b\w/g).join('')
);

export const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn custom-button',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

export const showError = async (error) => (
    await SwalWithBootstrapButtons.fire({
        icon: "error",
        title: "Something went wrong",
        text: error,
        confirmButtonText: "OK",
      })
);

export const showMessage = async (title, message) => (
    await SwalWithBootstrapButtons.fire({
        icon: "info",
        title: title,
        text: message,
        confirmButtonText: "OK",
      })
);

export const showConfirmation = async (title, message) => (
    await SwalWithBootstrapButtons.fire({
        icon: "error",
        title: title,
        text: message,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No"
    })
);

export const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

export const DividerOr = () => {
    return (
        <Row className="text-center mt-4">
            <Col>
                <hr/>
            </Col>
            <Col>
                <strong>or</strong>
            </Col>
            <Col>
                <hr/>
            </Col>
      </Row>
    )
}

export const sha256sumStrings = async (strings) => {
    // Concatenate the array of strings
    const concatenated = strings.join('');

    // Encode the concatenated string as UTF-8
    const encoder = new TextEncoder();
    const data = encoder.encode(concatenated);

    // Compute the SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert the hash buffer to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');

    return hashHex;
}

export const getClientHash = async () => {
    const data = {
        language: navigator.language,
        platform: navigator.platform,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        timezoneOffset: new Date().getTimezoneOffset(),
        userAgent: navigator.userAgent,
        cookiesEnabled: navigator.cookieEnabled,
        doNotTrack: navigator.doNotTrack,
        colorDepth: window.screen.colorDepth,
        deviceMemory: navigator.deviceMemory || 'Unknown',
        hardwareConcurrency: navigator.hardwareConcurrency || 'Unknown',
        // Add more data as needed
    };

    const strs = [data.language, data.platform, data.screenResolution,
        data.timezoneOffset, data.userAgent, data.cookiesEnabled, data.doNotTrack,
        data.colorDepth, data.deviceMemory, data.hardwareConcurrency
    ];

    const client_hash = await sha256sumStrings(strs);

    return client_hash;
}