import React from 'react';
import { Container} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { PricingPresentation } from './billing';
import Page from 'components/page';

const Pricing = () => {
  const [t] = useTranslation('common');

  return (
    <Page title={t('Pricing')} description={t('Pricing')}>
      <Container className="min-vh-100 my-5">
          <PricingPresentation/>
      </Container>
    </Page>
  );
};

export default Pricing;