import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { Consts } from "consts";
import { useTranslation } from "react-i18next";
import Page from "components/page";
const AboutPage = () => {
  const [t] = useTranslation('common');

  return (
    <Page title={t('About')} description={t('About')}>
        <Container className="min-vh-100 my-5">
            <Row className="justify-content-md-center">
                <Col>
                <h1 className="mb-4">{t('About')} {Consts.ProjectName}</h1>
                <p>
                {t('Welcome to')} <strong>{Consts.ProjectName}</strong> {t('your trusted partner for AI-powered stock analysis and investment recommendations.')}
                </p>

                <h3 className="mt-5 mb-3">{t('What We Offer')}</h3>
                <ul>
                    <li>
                    <strong>{t('AI-Powered Analysis')}:</strong> {t('Our advanced algorithms analyze market trends and stock performance to provide smart investment recommendations.')}
                    </li>
                    <li>
                    <strong>{t('Portfolio Monitoring')}:</strong> {t('Get real-time monitoring of your investments with instant alerts for trading opportunities.')}
                    </li>
                    <li>
                    <strong>{t('Investment Reports')}:</strong> {t('Receive detailed reports with specific trading recommendations and portfolio optimization strategies.')}
                    </li>
                </ul>

                <h3 className="mt-5 mb-3">{t('Our Mission')}</h3>
                <p>
                {t('At')} <strong>{Consts.ProjectName}</strong> {t(', our mission is to empower investors with data-driven insights and AI-powered analysis to make smarter investment decisions.')}
                </p>

                <h3 className="mt-5 mb-3">{t('Want to Help or Partner with Us?')}</h3>
                <p>
                {t('If you\'d like to help us improve our service or explore partnership opportunities, feel free to reach out to')} {' '}
                <a href={`mailto:${Consts.ContactEmail}`}>{Consts.ContactEmail}</a>.
                </p>
                </Col>
            </Row>
        </Container>
    </Page>
  );
};

export default AboutPage;