import React, { useState } from 'react';
import { Container, Row, Col, Alert, Form, Button } from 'react-bootstrap';
import getBackend from 'backend/backend';
import { Paths } from 'paths';
import { useTranslation } from "react-i18next";
import Page from 'components/page';

const VerifyEmail = () => {
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation('common');

    const handleSubmit = async (event) => {
        event.preventDefault();

        setError("");
        setLoading(true);

        const result = await getBackend().verifyEmail({ name, code });
        if (result.error != null) {
            setError(result.error);
        } else {
            window.location.href = Paths.Signin.path;
        }

        setLoading(false);
    };

    return (
        <Page title={t('Verify Email')} description={t('Verify Email')}>
            <Container>
                <Row className="justify-content-md-center vh-100">
                    <Col md={6}>
                        <h2 className="my-4 text-center">{t('Verify Email')}</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="name">
                                <Form.Label>{t('Email')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="custom-input"
                                    placeholder={t('Enter your email')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="code">
                                <Form.Label>{t('Verification Code')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="custom-input"
                                    placeholder={t('Enter your email verification code')}
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Button className="custom-button mt-3" variant="light" type="submit" disabled={loading}>
                                {loading ? t('Verifying...') : t('Verify Email')}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

export default VerifyEmail;
