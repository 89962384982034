import React from 'react';
import { Container, Card} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Page from 'components/page';
import { Consts } from "consts";

const Terms = () => {
  const [t] = useTranslation('common');

  return (
        <Page title={t('Terms of Service')} description={t('Terms of Service')}>
        <Container className="min-vh-100 my-5">
            <Card>
                <Card.Body>
                <h1 className="mb-4">Terms of Service</h1>
                <p><em>Last Updated: October 31, 2024</em></p>
                <p>
                    Welcome to {Consts.ProjectName} ("we," "us," or "our"). By accessing or using our AI-powered stock advisory web service ("Service"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our Service.
                </p>
                <hr />

                <h3>1. Acceptance of Terms</h3>
                <p>
                    By accessing or using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and any additional guidelines.
                </p>

                <h3>2. Description of Service</h3>
                <p>
                    Our Service provides users with AI-powered stock market analysis and investment recommendations. The Service analyzes market data and provides suggestions for potential investment opportunities.
                </p>
                <p>
                    <strong>Important Disclaimer:</strong> Our Service uses artificial intelligence to analyze market data and make predictions. However, stock market predictions are inherently uncertain and no AI system can guarantee accurate results. Past performance does not guarantee future results.
                </p>

                <h3>3. User Responsibilities</h3>
                <ul>
                    <li>
                    <strong>Investment Decisions:</strong> You are solely responsible for your investment decisions. Our Service provides analysis and recommendations, but the final decision to buy, sell, or hold any security is yours alone.
                    </li>
                    <li>
                    <strong>Risk Acknowledgment:</strong> You acknowledge that stock market investing involves substantial risk, including possible loss of principal.
                    </li>
                    <li>
                    <strong>Account Security:</strong> If you create an account, you are responsible for maintaining the confidentiality of your login information.
                    </li>
                </ul>

                <h3>4. Intellectual Property Rights</h3>
                <ul>
                    <li>
                    <strong>Our Rights:</strong> All content, features, and functionality of the Service, including our AI models and analysis algorithms, are the exclusive property of {Consts.ProjectName} and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
                    </li>
                    <li>
                    <strong>Your Rights:</strong> You retain all rights to any personal investment strategies or decisions you make based on our Service.
                    </li>
                </ul>

                <h3>5. Privacy Policy</h3>
                <p>
                    Our Privacy Policy outlines how we collect, use, and protect your personal information. By using the Service, you consent to the collection and use of information as described in the Privacy Policy.
                </p>

                <h3>6. Disclaimer of Warranties</h3>
                <p>
                    The Service is provided on an "AS IS" and "AS AVAILABLE" basis. We make no warranties, expressed or implied, regarding the reliability, accuracy, or completeness of the Service or any stock market predictions, recommendations, or analysis provided through our Service.
                </p>

                <h3>7. Limitation of Liability</h3>
                <p>
                    In no event shall {Consts.ProjectName}, its directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Service.
                </p>
                <p>
                    <strong>Financial Disclaimer:</strong> We are not liable for any financial losses, missed profits, or damages resulting from:
                </p>
                <ul>
                    <li>Investment decisions made based on our Service's recommendations</li>
                    <li>Accuracy or timing of our AI-generated market predictions</li>
                    <li>Technical issues or delays in our Service</li>
                    <li>Market conditions or external economic factors</li>
                </ul>
                <p>
                    You acknowledge that any reliance on our Service's predictions and recommendations is at your own financial risk.
                </p>

                <h3>8. Indemnification</h3>
                <p>
                    You agree to defend, indemnify, and hold harmless {Consts.ProjectName} and its affiliates from any claims, damages, obligations, losses, liabilities, costs, or debt arising from:
                </p>
                <ul>
                    <li>Your use of and access to the Service</li>
                    <li>Your investment decisions based on our Service</li>
                    <li>Your violation of any term of these Terms</li>
                    <li>Your violation of any third-party rights</li>
                </ul>

                <h3>9. Modifications to the Service and Terms</h3>
                <p>
                    We reserve the right to modify or discontinue the Service at any time without notice. We may also update these Terms from time to time. Continued use of the Service after any such changes shall constitute your consent to such changes.
                </p>

                <h3>10. Governing Law</h3>
                <p>
                    These Terms shall be governed and construed in accordance with the laws of the State of Wyoming, United States, without regard to its conflict of law provisions.
                </p>

                <h3>11. Severability</h3>
                <p>
                    If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in effect.
                </p>

                <h3>12. Entire Agreement</h3>
                <p>
                    These Terms constitute the entire agreement between us regarding our Service and supersede any prior agreements.
                </p>

                <h3>13. Contact Information</h3>
                <p>
                    If you have any questions about these Terms, please contact us at:
                </p>
                <ul>
                    <li><strong>Email:</strong> {Consts.ContactEmail}</li>
                </ul>

                <hr />
                <p>
                    By using our Service, you acknowledge that you have read and agree to these Terms of Service, and understand that all investment decisions and their outcomes are your sole responsibility.
                </p>
                </Card.Body>
            </Card>
        </Container>
        </Page>
  );
};

export default Terms;