import { React } from 'react';
import "styles/theme.css";
import Footer from './footer';
import Header from './header';
import { Consts } from 'consts';
import { Helmet } from 'react-helmet';

export const Page = (props) => {
    return (
        <>
            <Helmet>
                <title>{props.title} | {Consts.ProjectName}</title>
                <meta name="description" content={props.description} />
            </Helmet>
            <Header/>
                <main className="main-content">
                    {props.children}
                </main>
            <Footer/>
        </>
    );
};

export default Page;
