import React from "react";
import { Col, Row, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Paths } from "paths";
import Page from "components/page";
import "styles/theme.css";

const PageNotFound = () => {
  const { t } = useTranslation('common');

  return (
      <Page title={t('404 Page Not Found')} description={t('404 Page Not Found')}>
        <section className="vh-100 d-flex align-items-center justify-content-center">
          <Container>
            <Row>
              <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                <div>
                  <p className="lead my-4 message-text-color">
                    {t('Oops! This page is playing hide and seek.')}
                  </p>
                  <Button as={Link} className="d-inline-flex align-items-center justify-content-center mb-4" to={Paths.Home.path}>
                    {t('Teleport home')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Page>
  );
};

export default PageNotFound;
